<template>
    <div class="chat-preview md-card"
         v-on:click="select(chat)"
         v-bind:class="getClass()">
        <div style="display: flex">
            <div>
                <div class="chat-preview-clients" style="color: #227093">
                    <svg style="width: 14px; height: 14px; margin-right: 6px; margin-bottom: 2px;"
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path fill="#22709377"
                              d="M8 6C8 3.79 9.79 2 12 2S16 3.79 16 6 14.21 10 12 10 8 8.21 8 6M17 22H18C19.1 22 20 21.1 20 20V15.22C20 14.1 19.39 13.07 18.39 12.56C17.96 12.34 17.5 12.13 17 11.94V22M12.34 17L15 11.33C14.07 11.12 13.07 11 12 11C9.47 11 7.29 11.7 5.61 12.56C4.61 13.07 4 14.1 4 15.22V22H6.34C6.12 21.55 6 21.04 6 20.5C6 18.57 7.57 17 9.5 17H12.34M10 22L11.41 19H9.5C8.67 19 8 19.67 8 20.5S8.67 22 9.5 22H10Z"/>
                    </svg>
                    {{ chat.users.filter(it => it.type === 'patient').map(it => it.name).join(', ') }}
                </div>
                <div class="chat-preview-clients" style="color: #218c74">
                    <svg style="width: 14px; height: 14px; margin-right: 6px; margin-bottom: 2px;"
                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path fill="#218c7477"
                              d="M14.84,16.26C17.86,16.83 20,18.29 20,20V22H4V20C4,18.29 6.14,16.83 9.16,16.26L12,21L14.84,16.26M8,8H16V10A4,4 0 0,1 12,14A4,4 0 0,1 8,10V8M8,7L8.41,2.9C8.46,2.39 8.89,2 9.41,2H14.6C15.11,2 15.54,2.39 15.59,2.9L16,7H8M12,3H11V4H10V5H11V6H12V5H13V4H12V3Z"/>
                    </svg>
                    {{ chat.users.filter(it => it.type === 'doctor').map(it => it.name).join(', ') }}
                </div>
            </div>
            <div class="chat-preview-date"> {{ getChatTime(chat.messages[0].created) }}</div>
        </div>
        <div class="chat-preview-content">
            <div class="chat-preview-text">
              <span style="color: red; padding-right: 8px" v-if="!hasNotUnread()">&#9679;</span>{{ isClosed() ? 'Чат приостановлен' : chat.messages[0].content }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChatPreview',
    props: {
        chat: {
            type: Object,
            required: true
        },
        currentChatId: {
            type: Number,
            default: -1
        },
        select: {
            type: Function,
            required: true,
            default: () => {
            }
        }
    },
    methods: {
        hasNotUnread() {
          let seen = false
          let seenIds = this.chat.messages[0].seen.map(it => it.id)
          let userIds = this.chat.users.filter(it => it.type === 'patient').map(it => it.id)
          for (let i = 0; i < userIds.length; i++)
            if (seenIds.indexOf(userIds[i]) > -1) {
              seen = true
            }
          return seen
        },

        getClass() {
            return {
              'chat-preview-active': this.currentChatId === this.chat.id,
              'chat-preview-closed': this.isClosed()
            }
        },

        getChatTime(time) {
            const date = new Date(time)
            const currentDate = new Date()
            if (date.getDate() === currentDate.getDate()) return date.toLocaleTimeString().substring(0, 5)
            if (date.getDate() + 1 === currentDate.getDate()) return 'Вчера'
            return date.toLocaleDateString()
        },

        isClosed() {
          return new Date().getTime() > new Date(this.chat.deleted).getTime()
        },
    }
}
</script>

<style scoped>

.chat-preview {
    background: white;
    margin-bottom: 12px;
    padding: 12px 16px;
    cursor: pointer;
}

.chat-preview-active {
  background: #7bed9f;
}

.chat-preview:hover {
    background: #7bed9f77;
}

.chat-preview-clients {
    font-weight: 400;
    display: flex;
    color: #000;
    letter-spacing: 0.35px;
    font-size: 13px;
    width: 100%;
    align-items: center;
    margin-bottom: 4px;
}

.chat-preview-content {
    display: flex;
    align-items: center;
    font-size: 13px;
}

.chat-preview-text {
    color: #535c68;
    font-size: 13px;
    width: 100%;
    margin-right: 16px;
    max-lines: 2;
}

.chat-preview-date {
    color: #7f8fa6;
    font-size: 10px;
    letter-spacing: 1px;
    margin: 0 0 0 auto;
}

</style>
